import React , {useEffect, useState}from 'react';
import './SignIn.css';
import Navbar from '../Navbar/Navbar';
import { useNavigate} from 'react-router-dom';
import { initOTPless } from '../checkout/utils/initOTpless';
import Footer from '../Footer/Footer';
import Cart from '../Cartpage/Cart';
import { Helmet } from 'react-helmet-async';

const SignIn = () => {

const navigate = useNavigate()
  useEffect(() => initOTPless(callback), []);

  const callback = (otplessUser) => {
     console.log(otplessUser);
     navigate('/checkout');
  };
  const [isCartOpen, setIsCartOpen] = useState(false);
  
  // const [cartItems, setCartItems] = useState([]);


  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const closeCart = () => {
    setIsCartOpen(false);
  };

  
  return (
    <>
    <Helmet>
      <title>Door2fy | Secure Sign-In to Access Technical Services</title>
      <meta name="description" content="Sign in to Door2fy to book expert technical services for coding, MacBook, desktop, and server issues. Your account and manage your services easily." />
    </Helmet>

<Navbar toggleCart={toggleCart} cartItemCount={0} /> {/* Set cartItemCount to 0 for empty cart */}
      
      {isCartOpen && (
        <Cart onClose={closeCart} />
      )}
      <div className="sign-in-container">
      <div id="otpless-login-page"></div>

      </div>
       
 
    <Footer />
  </>
  );
};

export default SignIn;


